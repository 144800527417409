<template>
  <van-popup v-model="visible" position="bottom">
    <div class="house-name" v-if="houseNameList.length > 0">
      <span class="name-item" :class="{'isOld34': $isOld == 1}" v-for="(item, index) in houseNameList" :key="item.value" @click="streetDisabled && (index == 0 || index == 1) ? false : houseNameSelect(index)">
        <span style="text-decoration: underline">{{item.label}}</span><span v-if="index != houseNameList.length - 1"> - </span>
      </span>
    </div>
    <div class="house-search">
      <input v-model="value" :class="{'isOld30': $isOld == 1}" :placeholder="placeholder" @keyup.enter="onSearch" type="search" value="搜索" class="search-content" />
      <img v-if="value" :src="require('@/assets/img/clear.png')" class="search-clear" @click="onCancel">
      <img class="search-icon" :src="require('@/assets/img/search.png')" />
    </div>
    <div class="house-select">
      <template v-if="selectList.length > 0">
        <div class="select-item" v-for="item in selectList" :class="{'selected': houseNameList[selectIndex] && item.value == houseNameList[selectIndex].value,'isOld34': $isOld == 1}"
             :key="item.value" @click="houseSelect(item)">{{item.label}}</div>
      </template>
      <div v-else class="empty">暂无数据</div>
    </div>
    <div class="btn" @click="houseConfirm">确定</div>
  </van-popup>
</template>

<script>
import {getbelongSubArea} from '@/utils/common'
export default {
  name: "index",
  data () {
    return {
      visible: true,
      value: '',
      houseNameList: [],
      orgList: [],
      selectList: [],
      _selectList: [],
      selectIndex: 0,
      houseId: '',
      placeholder: '请输入小区名称',
      streetDisabled: false
    }
  },
  created() {
    let that = this
    getbelongSubArea(function (e) {
      // that.houseNameList[0] = e[0]
      e = e.map(item => {
        return {
          value: e.id,
          label: e.name,
          ...item
        }
      })
      that.selectList = e
      that._selectList = e
      that.orgList = e
      if (that.$toSuccess) {
        that.streetDisabled = true
        let house = that.selectList.filter(item => {
          if (item.value == that.$subarea) {
            return item
          }
        })
        if(house.lenth > 0){
          that.$set(that.houseNameList, 0, house[0])
          that.houseSelect(house[0])
        }
      }
    })
    // this.$http({
    //   url: this.$http.adornUrl(`/wxapp/building/house/liveWhichTree`),
    //   method: 'get',
    //   params: this.$http.adornParams({
    //     communityId: this.$globalData.userInfo.orgId
    //   })
    // }).then(({data}) => {
    //   if (data.code == 0) {
    //     this.houseNameList[0] = data.liveWhichTree[0]
    //     this.selectList = data.liveWhichTree
    //     this._selectList = data.liveWhichTree
    //     this.orgList = data.liveWhichTree
    //   }
    // })
  },
  methods: {
    onSearch () {
      this.selectList = JSON.parse(JSON.stringify(this._selectList))
      this.selectList = this.selectList.filter(item => item.label.indexOf(this.value) > -1)
    },
    onCancel () {
      this.value = ''
      this.selectList = JSON.parse(JSON.stringify(this._selectList))
    },
    houseNameSelect (index) {
      if (index != this.houseNameList.length - 1 || index == 0) {
        this.value = ''
        this.selectIndex = index
        if (index == 0) {
          this.placeholder = '请输入小区名称'
          this.selectList = this.orgList
          this._selectList = this.orgList
        } else if (index != this.houseNameList.length - 1) {
          this.placeholder = this.houseNameList[index - 1].placeholder
          this.selectList = this.houseNameList[index - 1].children
          this._selectList = this.houseNameList[index - 1].children
        }
        this.houseNameList = this.houseNameList.slice(0, index + 1)
      }
    },
    houseSelect (house) {
      this.value = ''
      this.$set(this.houseNameList, this.selectIndex, house)
      if (this.selectIndex == 0) { // 选择小区
        this.$http({
          url: this.$http.adornUrl('/wxapp/building/info/free/getBySubArea'),
          method: 'post',
          params: this.$http.adornParams({
            subArea: this.houseNameList[0].value
          }, false)
        }).then(({data})=> {
          if (data.code == 0) {
            this.selectIndex++
            // this.houseNameList[this.selectIndex] = data.buildings[0]
            this.houseNameList[this.selectIndex - 1].children = data.buildings
            this.placeholder = '请输入楼幢'
            this.houseNameList[this.selectIndex - 1].placeholder = '请输入楼幢'
            this.selectList = data.buildings
            this._selectList = data.buildings

            if (this.$toSuccess) {
              let house = this.selectList.filter(item => {
                if (item.value == this.$buildingId) {
                  return item
                }
              })
              if(house.lenth > 0){
                this.$set(this.houseNameList, 1, house[0])
                this.houseSelect(house[0])
              }

            }
          } else {
            this.$toast.fail(data.msg);
          }
        })
      } else if (this.selectIndex == 1) { // 选择楼幢
        if (house.divideHouse == 0) { // 不分房
          this.$http({
            url: this.$http.adornUrl('/wxapp/building/house/free/floorHouses'),
            method: 'get',
            params: this.$http.adornParams({
              id: house.value
            })
          }).then(({data}) => {
            if (data && data.code === 0) {
              this.houseId = data.map.list ? data.map.list[0].id : ''
            } else {
              this.$toast.fail(data.msg)
            }
          })
        } else if (house.divideUnit == 0) { // 不分单元
          this.$http({
            url: this.$http.adornUrl('/wxapp/building/house/free/floorHouses'),
            method: 'get',
            params: this.$http.adornParams({
              id: house.value
            })
          }).then(({data}) => {
            if (data && data.code === 0) {
              if (JSON.stringify(data.map) != '{}') {
                this.selectIndex++
                data.map.floor = data.map.floor.map(item => {
                  return {
                    value: item,
                    label: item + '层'
                  }
                })
                // this.houseNameList[this.selectIndex] = data.map.floor[0]
                this.houseNameList[this.selectIndex - 1].children = data.map.floor
                this.placeholder = '请输入楼层'
                this.houseNameList[this.selectIndex - 1].placeholder = '请输入楼层'
                this.selectList = data.map.floor
                this._selectList = data.map.floor
              }
            } else {
              this.$toast.fail(data.msg)
            }
          })
        } else {
          this.$http({
            url: this.$http.adornUrl('/wxapp/building/unit/free/listByBuildingId'),
            method: 'post',
            params: this.$http.adornParams({
              buildingId: house.value
            },false)
          }).then(({data})=> {
            if (data.code == 0) {
              this.selectIndex++
              // this.houseNameList[this.selectIndex] = data.units[0]
              this.houseNameList[this.selectIndex - 1].children = data.units
              this.placeholder = '请输入单元'
              this.houseNameList[this.selectIndex - 1].placeholder = '请输入单元'
              this.selectList = data.units
              this._selectList = data.units
            } else {
              this.$toast.fail(data.msg)
            }
          })
        }
      } else if (this.selectIndex == 2) {
        if (house.label.indexOf('层') > -1) { // 不分单元
          this.$http({
            url: this.$http.adornUrl('/wxapp/building/house/free/floorHouses'),
            method: 'get',
            params: this.$http.adornParams({
              id: this.houseNameList[1].value,
              floor: house.value
            })
          }).then(({data}) => {
            if (data && data.code === 0) {
              if (JSON.stringify(data.map) != '{}') {
                this.selectIndex++
                data.map.list = data.map.list.map(item => {
                  return {
                    value: item.id,
                    label: item.name,
                    ...item
                  }
                })
                // this.houseNameList[this.selectIndex] = data.map.list[0]
                this.houseNameList[this.selectIndex - 1].children = data.map.list
                this.placeholder = '请输入室号'
                this.houseNameList[this.selectIndex - 1].placeholder = '请输入室号'
                this.selectList = data.map.list
                this._selectList = data.map.list
              }
            } else {
              this.$toast.fail(data.msg)
            }
          })
        } else {
          this.$http({
            url: this.$http.adornUrl('/wxapp/building/house/free/floorHouses'),
            method: 'get',
            params: this.$http.adornParams({
              id: this.houseNameList[1].value,
              unit: house.value
            })
          }).then(({data}) => {
            if (data && data.code === 0) {
              if (JSON.stringify(data.map) != '{}') {
                this.selectIndex++
                data.map.floor = data.map.floor.map(item => {
                  return {
                    value: item,
                    label: item + '层'
                  }
                })
                // this.houseNameList[this.selectIndex] = data.map.floor[0]
                this.houseNameList[this.selectIndex - 1].children = data.map.floor
                this.placeholder = '请输入楼层'
                this.houseNameList[this.selectIndex - 1].placeholder = '请输入楼层'
                this.selectList = data.map.floor
                this._selectList = data.map.floor
              }
            } else {
              this.$toast.fail(data.msg)
            }
          })
        }
      } else if (this.selectIndex == 3) {
        this.$http({
          url: this.$http.adornUrl('/wxapp/building/house/free/floorHouses'),
          method: 'get',
          params: this.$http.adornParams({
            id: this.houseNameList[1].value,
            unit: this.houseNameList[2].value,
            floor: house.value
          })
        }).then(({data}) => {
          if (data && data.code === 0) {
            if (JSON.stringify(data.map) != '{}') {
              this.selectIndex++
              data.map.list = data.map.list.map(item => {
                return {
                  value: item.id,
                  label: item.name,
                  ...item
                }
              })
              // this.houseNameList[this.selectIndex] = data.map.list[0]
              this.houseNameList[this.selectIndex - 1].children = data.map.list
              this.placeholder = '请输入室号'
              this.houseNameList[this.selectIndex - 1].placeholder = '请输入室号'
              this.selectList = data.map.list
              this._selectList = data.map.list
            } else {
              this.houseId = house.value
            }
          } else {
            this.$toast.fail(data.msg)
          }
        })
      } else {
        this.houseId = house.value
      }
    },
    houseConfirm () {
      this.visible = false
      this.$emit('houseConfirm', this.houseId, this.houseNameList)
    }
  }
}
</script>

<style scoped lang="scss">
.van-popup {
  padding-top: 24px;
  border-radius: 16px 16px 0 0;
}
.house-name {
  width: 750px;
  height: 88px;
  padding: 24px 30px;
}
.name-item {
  color: #4581F8;
  font-size: 30px;
  line-height: 42px;
}
.house-search {
  position: relative;
  margin: 24px auto;
  width: 690px;
  height: 64px;
  background: rgba(0,0,0,0.04);
  border-radius: 8px;
  .search-content {
    font-size: 28px;
    padding-top: 16px;
    color: #666;
    padding-left: 52px;
    display: block !important;
    width: 560px !important;
    margin-left: 30px !important;
    background-color: transparent;
    border: none;
  }
  .search-content::placeholder {
    color: #999;
  }
  .search-clear {
    position: absolute;
    top: 16px;
    right: 24px;
    width: 32px;
    height: 32px;
  }
  .search-icon {
    position: absolute;
    top: 16px;
    left: 24px;
    width: 32px;
    height: 32px;
  }
}
.house-select {
  padding: 0 12px 58px 30px;
  max-height: 276px;
  overflow: auto;
}
.select-item {
  width: 218px;
  margin: 0 18px 22px 0;
  line-height: 74px;
  background: #F5F5F5;
  color: #666;
  font-size: 28px;
  text-align: center;
  display: inline-block;
  border-radius: 12px;
  padding: 0 24px;
  overflow:hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow:ellipsis;
}
.selected {
  background: #E9EFFD;
  color: #4581F8;
  position: relative;
}
.selected::after {
  content: '';
  position: absolute;
  right: 0;
  bottom: 0;
  width: 32px;
  height: 34px;
  background-image: url('~@/assets/img/selected-icon.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  z-index: 999;
}
.btn {
  width: 690px;
  line-height: 88px;
  background: #4581F8;
  border-radius: 8px;
  font-size: 30px;
  color: #fff;
  margin: 24px auto 40px;
  text-align: center;
}
.empty {
  color: #999;
  font-size: 30px;
  width: 100%;
  text-align: center;
  margin-top: 40px;
}
</style>
